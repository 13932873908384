<template>
  <b-row>
    <b-col v-if="event">
      <b-row class="p-4 mb-2 bg-mellow">
        <b-col>
          <b-row>
            <b-col>
              <h1 class="h1 mb-0">{{ event.nwpName }}</h1>
            </b-col>
            <b-col cols="12 d-none d-md-block">
              <h3 class="text-muted">{{ formatDate(event.startDate) }} {{ $t(event.nwpCanton) }} - {{ $t(event.venue) }} | {{ $t(event.nwpCategory) }}</h3>
            </b-col>
            <b-col cols="12 d-block d-md-none">
              <h3 class="text-muted">{{ formatDate(event.startDate) }}<br>{{ $t(event.nwpCanton) }} - {{ $t(event.venue) }}<br>{{ $t(event.nwpCategory) }}</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-link @click="goBack">{{ $t('event_anmeldung.label_back') }}</b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="p-4 mb-2 bg-primary" v-if="kidSearch.display">
        <b-col>
          <b-row>
            <b-col>
              <h2 class="h5 text-white">{{ $t('event_anmeldung.label_anmeldung') }}</h2>
            </b-col>
          </b-row>
          <KidSearch v-if="event" :key="kidSearch.id" :event="event" @kidSelected="displayKidSearchApplication" />
          <b-row v-if="!eventNeedsQuali">
            <b-col>
              <b-link @click="displayFormApplication" class="text-white"><font-awesome-icon icon="arrow-right" /> {{ $t('event_anmeldung.label_cant_find_username') }}</b-link>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <p class="text-white"><font-awesome-icon icon="arrow-right" /> <span v-html="$t('event_anmeldung.label_qualification_alert')"></span></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="p-4 mb-2 bg-mellow" v-if="kidSearchApplication.display">
        <b-col>
          <KidSearchApplication v-if="event && kidSearch.selectedKid" :key="kidSearchApplication.id" :kid="kidSearch.selectedKid" :event="event" @reset="resetView" />
        </b-col>
      </b-row>
      <b-row class="p-4 mb-2 bg-mellow" v-if="formApplication.display">
        <b-col>
          <FormApplication v-if="event" :key="formApplication.id" :event="event" @reset="resetView" />
        </b-col>
      </b-row>
      <b-row class="p-4 mb-2 bg-mellow" v-if="coachApplicationSummary.display">
        <b-col>
          <b-row>
            <b-col sm="12" md="6">
              <b-row>
                <b-col>
                  <h2 class="h5">
                    {{ $t('event_anmeldung.label_zusammenfassung') }}
                  </h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_ort') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.venue }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_sportanlage') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.sportField }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_adresse') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.nwpAddress }}, {{ event.nwpZipCode }} {{ event.venue }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_datum') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ formatDate(event.startDate) }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_startzeit') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ formatTime(event.startTime) }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_veranstalter') }}:</p>
                </b-col>
                <b-col>
                  <p v-if="event.webpage">
                    <b-link :href="event.webpage" target="_blank">{{
                      event.schoolOrOrganisation
                    }}</b-link>
                  </p>
                  <p v-else>
                    {{ event.schoolOrOrganisation }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_details.label_homologation') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ $t(event.nwpHomologation) }}
                  </p>
                </b-col>
              </b-row>
              <b-row v-if="event.remark">
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_spezielle_bemerkungen') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.remark }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_anmeldeschluss') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ formatDate(event.deadline) }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_nachmeldungen_auf_platz_möglich') }}:</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.registrationOnSitePossible === true ? "Ja" : "Nein" }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t('event_anmeldung.label_startgeld') }}:</p>
                </b-col>
                <b-col>
                  <p>CHF {{ event.entryFee }}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="p-4 mb-2 bg-mellow" v-if="coachApplication.display">
        <b-col>
          <b-row>
            <b-col>
              <CoachApplication v-if="event" :key="coachApplication.id" :event="event" @login="displayCoachApplication" @reset="resetView"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import KidSearch from './KidSearch';
import KidSearchApplication from './KidSearchApplication';
import FormApplication from './FormApplication'
import CoachApplication from './CoachApplication'

export default {
  components: {
    KidSearch,
    KidSearchApplication,
    FormApplication,
    CoachApplication
  },
  data() {
    return {
      event: null,
      eventNeedsQuali: false,
      kidSearch: {
        id: 1,
        selectedKid: null,
        display: true,
      },
      kidSearchApplication: {
        id: 1,
        display: false,
      },
      formApplication: {
        id: 1,
        display: false,
      },
      coachApplication: {
        id: 1,
        display: true
      },
      coachApplicationSummary: {
        display: false
      },
    };
  },
  props: {
    eventId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.getEventData();
  },
  methods: {
    scrollToTop() {
      window.parent.postMessage({action: 'scrollTo', x: 0, y: 0}, "*");
    },
    goBack() {
      if (this.kidSearch.display === true && this.kidSearchApplication.display === false && this.coachApplication.display == true && this.formApplication.display === false) {
        this.$router.push(`/${this.$i18n.locale}/veranstaltungen/${this.event.id}`);
      } else {
        this.resetView();
      }
    },
    getEventData() {
      const id = this.eventId;

      this.$store
        .dispatch("wettkampf/getListe")
        .then((resp) => {
          var events = resp.data;

          if (events.length > 0) {
            for (let i = 0; i < events.length; i++) {
              if (events[i].id == id) {
                this.event = events[i];
                break; // Verlassen den for-loop, wenn der passende Event gefunden werden konnte
              }
            }
            
            // Prüfe, ob eine Qualifikation notwendig ist
            this.eventNeedsQuali = this.event.nwpCategory == 'Lov.Sat.NWPCategory.SprintSwissFinal' || (this.event.nwpCategory == 'Lov.Sat.NWPCategory.SprintCantonFinal' && this.event.nwpEligible == 'Lov.Sat.NWPEligible.SprintCantonFinalQualificationRequired')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      const options = {day: "2-digit", month: "2-digit", year: "numeric"};
      return date.toLocaleDateString('de', options);
    },
    formatTime(ms) {
      let date = new Date()
      date.setTime(ms);
      const hours = date.getUTCHours().toString().padStart(2,0);
      const minutes = date.getUTCMinutes().toString().padStart(2,0);
      return `${hours}:${minutes} Uhr`;
    },
    displayKidSearchApplication(kid) {
      this.kidSearch.selectedKid = kid;

      this.coachApplication.display = false;
      this.kidSearch.display = false;
      this.formApplication.display = false;
      this.coachApplicationSummary.display = false;
      this.kidSearchApplication.display = true;
      this.$helpers.scrollToTop();
    },
    displayFormApplication() {
      this.coachApplication.display = false;
      this.kidSearch.display = false;
      this.kidSearchApplication.display = false;
      this.coachApplicationSummary.display = false;
      this.formApplication.display = true;
      this.$helpers.scrollToTop();
    },
    displayCoachApplication() {
      this.kidSearch.display = false;
      this.kidSearchApplication.display = false;
      this.formApplication.display = false;
      this.coachApplication.display = true;
      this.coachApplicationSummary.display = true;
      this.$helpers.scrollToTop();
    },
    resetView() {
      this.kidSearch.id++;
      this.kidSearchApplication.id++;
      this.formApplication.id++;
      this.coachApplication.id++;

      this.kidSearch.display = true;
      this.coachApplication.display = true;
      this.kidSearchApplication.display = false;
      this.formApplication.display = false;
      this.coachApplicationSummary.display = false;
      this.$helpers.scrollToTop();
    }
  }
};
</script>